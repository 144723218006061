import { NEON_TOKEN_MINT_DECIMALS, SPLToken } from '@neonevm/token-transfer-core';
import { environment } from '../environments/environment';

export const ERROR_MESSAGE = 'Something was wrong...';

export const TRANSACTION_TIMEOUT = 18e5;
export const TRANSACTION_FEE = 5e3;
export const UNITS_LIMIT = 250_000;
export const MICRO_LAMPORTS = 1e-6;

export const CHAIN_ID = environment.chainId;

export const NEON = 'NEON';
export const W_NEON = 'wNEON';
export const SOL = 'SOL';
export const W_SOL = 'wSOL';
export const USDT = 'USDT';
export const USDC = 'USDC';
export const W_ETH = 'WETH';
export const LST = 'LST';
export const JitoSOL = 'JitoSOL';
export const mSOL = 'mSOL';
export const Bonk = 'Bonk';
export const MELL = 'MELL';
export const MNGO = 'MNGO';
export const T2080 = '2080';
export const PYTH = 'PYTH';
export const W_BTC = 'WBTC';
export const JITO = 'JTO';
export const BLZE = 'BLZE';
export const JSOL = 'JSOL';
export const CGT = 'CGT';
export const JUP = 'JUP';
export const $WIF = '$WIF';
export const WEN = 'WEN';
export const POPCAT = 'POPCAT';
export const WHALE = 'WHALE';
export const HEGE = 'HEGE';
export const SOLE = 'SOLE';
export const KART = 'KART';
export const RZ =  'RZ';

export const NEON_TOKEN_MODEL: SPLToken = {
  chainId: 0,
  address_spl: environment.neon.token_mint,
  address: '',
  decimals: NEON_TOKEN_MINT_DECIMALS,
  name: 'Neon',
  symbol: NEON,
  logoURI: 'https://raw.githubusercontent.com/neonlabsorg/token-list/main/neon-token-logo.svg'
};

export const SOL_TOKEN_MODEL = {
  name: 'Solana SOL',
  symbol: SOL,
  logoURI: 'https://raw.githubusercontent.com/neonlabsorg/token-list/master/assets/solana-sol-logo.svg'
} as SPLToken;

export const NEON_USD = 170;
export const SOL_USD = 17500;
